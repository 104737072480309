import React from 'react';
import { useFormikContext } from 'formik';
import coins from '~/assets/images/coins.svg';

const DonationFlat = () => {
  const { values } = useFormikContext();
  var splitedUserName = values.user_name.split('');

  return (
    <div
      className="donation-flat"
      style={{
        animationName: `${values.animation_in}`,
        animationDuration: '2s',
      }}
      onAnimationEnd={(e) => {
        setTimeout(function () {
          e.target.style.animationName = `${values.animation_out}`;
        }, 1 * 1000);
      }}
    >
      <div className="donation-flat-wrapper">
        <div className="donation-flat-count">
          <div className="donation-flat-count-wrapper">
            <img src={coins} width="36" height="36" alt="Donation" />
            <span>${values.count}</span>
          </div>
        </div>
        <span className="donation-flat-author">
          {splitedUserName.map((char, index) => {
            return (
              <b key={index} className={`animated-letter tada`}>
                {char}
              </b>
            );
          })}
        </span>
        {/* <div className="donation-flat-avatar">
          <img src={avatar} width="80" height="80" alt="Avatar" />
        </div> */}
      </div>
    </div>
  );
};

export default DonationFlat;
