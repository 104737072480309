import React from 'react';
import { outAnimationList } from './constants';

export const withOutAnimation = (Component) => {
  return ({ ...props }) => (
    <Component
      placeholder="Animation Out"
      style={{ width: 150 }}
      options={outAnimationList}
      {...props}
    />
  );
};
