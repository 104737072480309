import React from 'react';
import { useFormikContext } from 'formik';

const DonationGif = ({ name = '@mendez', count = 44 }) => {
  const { values } = useFormikContext();
  var splitedUserName = values.user_name.split('');
  var splitedCount = values.count.toString().split('');

  return (
    <div
      className="alertbox"
      data-layout="bottom"
      style={{ animationName: `${values.animation_in}` }}
      onAnimationEnd={(e) => {
        setTimeout(function () {
          e.target.style.animationName = `${values.animation_out}`;
        }, 1 * 1000);
      }}
    >
      <div className="alertbox-wrapper">
        <div className="alertbox-media-wrapper">
          <div
            className="alertbox-image"
            style={{ backgroundImage: `url(${values.image_url})` }}
          ></div>
        </div>
        <div className="alertbox-text-wrapper">
          <div className="alertbox-text">
            <div
              className="alertbox-message"
              style={{
                fontFamily: `${values.font}`,
                fontSize: '36px',
                fontWeight: 700,
                color: 'rgb(255, 255, 255)',
              }}
            >
              <span>
                <span
                  className={`animated-letter ${values.text_animation}`}
                  style={{ color: 'rgb(50, 195, 166)' }}
                >
                  @
                </span>
                {splitedUserName.map((char, index) => {
                  return (
                    <span
                      key={index}
                      className={`animated-letter ${values.text_animation}`}
                      style={{ color: 'rgb(50, 195, 166)' }}
                    >
                      {char}
                    </span>
                  );
                })}
              </span>{' '}
              <span>{values.text_template}</span>{' '}
              <span>
                <span
                  className={`animated-letter ${values.text_animation}`}
                  style={{ color: 'rgb(50, 195, 166)' }}
                >
                  $
                </span>
                {splitedCount.map((char, index) => {
                  return (
                    <span
                      key={index}
                      className={`animated-letter ${values.text_animation}`}
                      style={{ color: 'rgb(50, 195, 166)' }}
                    >
                      {char}
                    </span>
                  );
                })}
              </span>
            </div>
            <div
              className="alertbox-user-message"
              style={{
                fontSize: '21px',
                fontWeight: 400,
                color: 'rgb(255, 255, 255)',
              }}
            >
              {values.text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationGif;
