import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

import videoCoding from '~/assets/video/coding.mp4';
import videoAuthor from '~/assets/video/author.mp4';

import telegram from '~/assets/images/telegram.svg';
import coins from '~/assets/images/coins.svg';
import discord from '~/assets/images/discord.svg';
import shopify from '~/assets/images/shopify.svg';

const Emulator = () => {
  const wrapper = useRef();
  const timeline = useRef();

  useEffect(() => {
    const query = gsap.utils.selector(wrapper);
    const el = query('[data-emul-alert]');
    timeline.current = gsap.timeline({ repeat: -1 });
    timeline.current.to(el, {
      y: '-=600',
      duration: 4,
      opacity: 0,
      ease: 'none',
    });
  }, []);

  return (
    <div className="emulator">
      <div className="emulator-main">
        <div className="emulator-video">
          <video loop autoPlay muted playsInline>
            <source src={videoCoding} type="video/mp4" />
          </video>
        </div>
        <div className="emulator-author">
          <video loop autoPlay muted playsInline>
            <source src={videoAuthor} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="emulator-alerts">
        <ul className="emulator-alerts-wrapper" ref={wrapper}>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <img src={discord} width="28" height="28" alt="Discord" />
              <div className="emulator-alert-text">
                <b>John</b> create a new poll
              </div>
            </div>
          </li>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <img src={shopify} width="28" height="28" alt="Shopify" />
              <div className="emulator-alert-text">
                A. Killorn bought a T-shirt
              </div>
            </div>
          </li>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <img src={coins} width="28" height="28" alt="Donation" />
              <div className="emulator-alert-text gold">
                <b>$15</b>
              </div>
            </div>
          </li>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <div className="emulator-alert-circle telegram">
                <img
                  src={telegram}
                  width="18"
                  height="18"
                  alt="Telegram message"
                  style={{ position: 'relative', left: '-1px' }}
                />
              </div>
              <div className="emulator-alert-text">
                <b>@bbdankeo</b>
              </div>
            </div>
          </li>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <img src={discord} width="28" height="28" alt="Discord" />
              <div className="emulator-alert-text">
                <b>John</b> create a new poll
              </div>
            </div>
          </li>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <img src={shopify} width="28" height="28" alt="Shopify" />
              <div className="emulator-alert-text">
                A. Killorn bought a T-shirt
              </div>
            </div>
          </li>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <img src={coins} width="28" height="28" alt="Donation" />
              <div className="emulator-alert-text gold">
                <b>$15</b>
              </div>
            </div>
          </li>
          <li className="emulator-alert" data-emul-alert>
            <div className="emulator-alert-wrapper">
              <div className="emulator-alert-circle telegram">
                <img
                  src={telegram}
                  width="18"
                  height="18"
                  alt="Telegram message"
                  style={{ position: 'relative', left: '-1px' }}
                />
              </div>
              <div className="emulator-alert-text">
                <b>@bbdankeo</b>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Emulator;
