import React, { useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';

const renderer = ({
  hours,
  minutes,
  seconds,
  milliseconds,
  completed,
  message,
}) => {
  if (completed) {
    return (
      <>
        <div className="preview-countdown-timer">
          <span>00</span> : <span>00</span> : <span>00</span> : <span>000</span>
        </div>
        <div className="preview-countdown-text">We’re getting ready</div>
      </>
    );
  } else {
    return (
      <>
        <div className="preview-countdown-timer">
          <span>{zeroPad(hours)}</span> : <span>{zeroPad(minutes)}</span> :{' '}
          <span>{zeroPad(seconds)}</span> :{' '}
          <span>{milliseconds < 100 ? '0' + milliseconds : milliseconds}</span>
        </div>
        <div className="preview-countdown-text">Starting soon</div>
      </>
    );
  }
};

const TimeToStart = ({ timer = 1000 * 5 }) => {
  var [show, setShow] = useState(false);

  var hours = padZero(Math.floor(timer / (60 * 60 * 1000)));
  var mins = padZero(Math.floor(timer / (1000 * 60)));
  var seconds = padZero(Math.floor((timer / 1000) % 60));
  var mscds = padMscZero(timer % 1000);

  function padZero(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  function padMscZero(value) {
    if (value < 10) return `00${value}`;
    if (value < 100) return `0${value}`;
    return `${value}`;
  }

  const handleStart = () => {
    setShow(!show);
  };

  return (
    <div className="preview-countdown">
      {!show && (
        <>
          <div className="preview-countdown-timer">
            <span>{hours}</span> : <span>{mins}</span> : <span>{seconds}</span>{' '}
            : <span>{mscds}</span>
          </div>
          <div className="preview-countdown-start">
            <button className="btn secondary" onClick={handleStart}>
              Start
            </button>
          </div>
        </>
      )}
      {show && (
        <Countdown
          date={Date.now() + timer}
          precision={3}
          intervalDelay={0}
          renderer={renderer}
        />
      )}
    </div>
  );
};

export default TimeToStart;
