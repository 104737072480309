import React from 'react';
import { fontList } from './constants';

export const withFont = (Component) => {
  return ({ ...props }) => (
    <Component
      placeholder="Choose a font"
      style={{ width: 200 }}
      options={fontList}
      {...props}
    />
  );
};
