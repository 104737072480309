export const initialValues = {
  token: 'QXfeY9aew4k6Mfdk',
  bot: false,
  trigger_word: 'Hey',
  channel_id: 'GENERAL',
  channel_name: 'general',
  message_id: 'dmtQojQ68mpnLh557',
  timestamp: '2021-09-15T07:23:27.618Z',
  user_id: 'nFTedP9xigF8Jxrsk',
  user_name: 'smart_goblin',
  text: 'for coffee',
  siteUrl: 'https://chat21.advsec.io',
  //
  count: 44,
  text_template: 'donated',
  image_url: 'https://thumbs.gfycat.com/ClumsyBlissfulFlyingsquirrel.webp',
  animation_in: 'none',
  animation_out: 'none',
  text_animation: 'rubberBand',
  font: 'TT Firs Neue',
};
