import React from 'react';
import Logo from './Logo';

const Header = ({ path }) => {
  return (
    <header className="page-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4 col-7">
            <Logo></Logo>
          </div>
          <div className="col-md-8 col-5 d-flex flex-row-reverse"></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
