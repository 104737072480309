import React, { useRef, useEffect } from 'react';
import '~/assets/styles/main.scss';
import Layout from '~/components/layout';
import Seo from '~/components/seo';
import Button from '~/components/Button';
import Sandbox from '~/components/Sandbox';
import Emulator from '~/components/Emulator';
import DonationGif from '~/components/alerts/DonationGif';
import DonationFlat from '~/components/alerts/DonationFlat';
import SellNotification from '~/components/alerts/SellNotification';
import MessangerAlert from '~/components/alerts/MessangerAlert';
import TimeToStart from '~/components/alerts/PreviewCountdown';
import { initialValues } from '../components/alerts/config';

import ClientOnly from '~/components/ClientOnly.js';

// import tshirt from '~/assets/images/t-shirt.jpeg';
import shopify from '~/assets/images/shopify.svg';
import wix from '~/assets/images/wix.svg';
import squarespace from '~/assets/images/squarespace.svg';
import telegram from '~/assets/images/telegram.svg';
import discordWhite from '~/assets/images/discordWhite.svg';
import arrow from '~/assets/images/arrow.svg';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const IndexPage = () => {
  const sandboxBlock = useRef();
  const sandboxCode = useRef();
  const sandboxHelp = useRef();

  useEffect(() => {
    ScrollTrigger.create({
      start: 'top top-=70px',
      trigger: sandboxBlock.current,
      once: true,
      onEnter: () => {
        gsap.to(sandboxCode.current, {
          duration: 1.5,
          className: 'sandbox-mainarea-code highlight',
        });
        gsap.to(sandboxHelp.current, {
          opacity: 1,
          duration: 1.5,
        });
        setTimeout(() => {
          gsap.to(sandboxCode.current, { className: 'sandbox-mainarea-code' });
          gsap.to(sandboxHelp.current, { opacity: 0 });
        }, 5000);
      },
    });
  }, []);

  return (
    <Layout>
      <Seo />
      <section className="page-section intro">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col">
              <div className="row">
                <div className="col-lg-10">
                  <h1 className="large mb-36">
                    Get a custom notifications for your video stream
                  </h1>
                  <Button scroll={sandboxBlock} />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col">
              <Emulator />
            </div>
          </div>
        </div>
      </section>
      <section className="page-section secondary" ref={sandboxBlock}>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-center mb-72">
                Show alerts for donates, follows, sales, & more
              </h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <Sandbox
                inline={true}
                initialValues={initialValues}
                as={[<DonationGif />, <DonationFlat />]}
                controls={['Custom', 'Default']}
                ref={sandboxCode}
              ></Sandbox>
              <div ref={sandboxHelp} className="helper-info">
                <p className="helper-info-text">Try to edit values</p>
                <img src={arrow} width="48" height="48" alt="Help" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h2 className="large mb-48">
                Discover about purchases and thanks fans
              </h2>
              {/* <div className="mb-72">
                    <div className="shop-emulator">
                      <div className="shop-emulator-image">
                        <img
                          src={tshirt}
                          width="200px"
                          height="auto"
                          alt="T-shirt"
                        />
                      </div>
                      <div className="shop-emulator-cart">
                        <div className="shop-emulator-cart-wrapper">
                          <span>+</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
              <ul className="icons-list">
                <li className="icons-list-item">
                  <img src={shopify} width="38" height="38" alt="Shopify" />
                </li>
                <li className="icons-list-item">
                  <img src={wix} width="38" height="38" alt="Wix" />
                </li>
                <li className="icons-list-item">
                  <img
                    src={squarespace}
                    width="32"
                    height="32"
                    alt="Squarespace"
                  />
                </li>
              </ul>
            </div>
            <div className="col-lg-7">
              <Sandbox
                initialValues={initialValues}
                code={false}
                skin="invert"
                as={[<SellNotification />]}
                controls={['Example 1']}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="page-section secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="row">
                <div className="col-lg-10">
                  <h2 className="large mb-48">
                    Get notifications from popular messangers
                  </h2>
                  <ul className="icons-list">
                    <li
                      className="icons-list-item"
                      style={{ backgroundColor: '#2ba3d5' }}
                    >
                      <img
                        src={telegram}
                        width="38"
                        height="38"
                        alt="Telegram"
                      />
                    </li>
                    <li
                      className="icons-list-item"
                      style={{ backgroundColor: '#5865F2' }}
                    >
                      <img
                        src={discordWhite}
                        width="38"
                        height="38"
                        alt="Dscord"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <Sandbox
                initialValues={initialValues}
                code={false}
                as={[
                  <MessangerAlert name="telegram" />,
                  <MessangerAlert name="discord" />,
                ]}
                controls={['Telegram', 'Discord']}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="row">
                <div className="col-lg-11">
                  <h2 className="large mb-48">Launch a timers in two clicks</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <Sandbox
                initialValues={{}}
                code={false}
                skin="invert"
                as={[
                  <ClientOnly>
                    <TimeToStart />
                  </ClientOnly>,
                ]}
                controls={['Timer 1']}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
