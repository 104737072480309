import React from 'react';
import avatar from '~/assets/images/avatar1.jpeg';
import tshirt from '~/assets/images/t-shirt.jpeg';
// import uuid from 'react-uuid';

const DonationFlat = () => {
  return (
    <div
      // key={uuid()}
      className="sell-notification"
      style={{
        animationName: 'fadeInLeft',
        animationDuration: '2s',
      }}
    >
      <div className="sell-notification-wrapper">
        <div
          className="sell-notification-top"
          style={{ backgroundImage: `url(${tshirt})` }}
        >
          <div className="sell-notification-avatar">
            <img src={avatar} width="80" height="80" alt="Avatar" />
          </div>
        </div>
        <div className="sell-notification-bottom">
          <div className="sell-notification-author">
            <b>@johnsnow</b>
          </div>
          <div className="sell-notification-text">Bought your merch</div>
        </div>
      </div>
    </div>
  );
};

export default DonationFlat;
