import React from 'react';
import telegram from '~/assets/images/telegram.svg';
import discord from '~/assets/images/discordWhite.svg';
import uuid from 'react-uuid';

const MessangerAlert = ({ name = '' }) => {
  return (
    <div
      key={uuid()}
      className={`messanger-alert ${name}`}
      style={{ animationName: 'zoomIn' }}
    >
      <div className="messanger-alert-wrapper">
        <div className="messanger-alert-icon">
          <div className="messanger-alert-icon-wrapper">
            <img
              src={name === 'discord' ? discord : telegram}
              width="36"
              height="36"
              alt="Telegram"
            />
          </div>
        </div>
        <div className="messanger-alert-text">
          <span>Hey, what's your camera for streaming?</span>{' '}
          <span className="messanger-alert-author"> - chuckNorris</span>
        </div>
      </div>
    </div>
  );
};

export default MessangerAlert;
