import React from 'react';
import { inAnimationList } from './constants';

export const withInAnimation = (Component) => {
  return ({ ...props }) => (
    <Component
      placeholder="Animation In"
      style={{ width: 150 }}
      options={inAnimationList}
      {...props}
    />
  );
};
