import React, { useState, useEffect, useRef } from 'react';
import { withInAnimation } from './withInAnimation';
import { withOutAnimation } from './withOutAnimation';
import { withTextAnimation } from './withTextAnimation';
import { withFont } from './withFont';
import { withField } from './withField';

var SelectField = ({ name, value, setFieldValue, options, ...props }) => {
  var [textValue, setTextValue] = useState(value);
  var ref = useRef();

  useEffect(() => {
    calcComponentWidth(textValue);
    name && setFieldValue && setFieldValue(name, textValue);
  }, [name, textValue, setFieldValue]);

  function onChange(event) {
    calcComponentWidth(event.target.value);
    setTextValue(event.target.value);
  }

  const calcComponentWidth = (value) => {
    ref.current.style.width = (value.length + 1) * 9.5 + 20 + 'px';
  };

  return (
    <select ref={ref} value={textValue} onChange={onChange}>
      {options &&
        options.map((name, index) => (
          <option key={index} value={name}>
            {Boolean(name) ? name : 'none'}
          </option>
        ))}
    </select>
  );
};

export const SelectInAnimation = withField(withInAnimation(SelectField));
export const SelectOutAnimation = withField(withOutAnimation(SelectField));
export const SelectTextAnimation = withField(withTextAnimation(SelectField));
export const SelectFontFamily = withField(withFont(SelectField));
