import React, { useState, forwardRef } from 'react';
import { Formik, Form, Field } from 'formik';
import InputTextField from './InputTextField';
import {
  SelectInAnimation,
  SelectOutAnimation,
  SelectTextAnimation,
  SelectFontFamily,
} from './SelectField';

const Sandbox = forwardRef(
  ({ inline = false, as, skin, controls, initialValues, code = true }, ref) => {
    var [example, setExample] = useState(0);

    return (
      <div className={`sandbox ${skin ? skin : ''}`}>
        <Formik enableReinitialize={true} initialValues={{ ...initialValues }}>
          {({ values }) => (
            <div className={`sandbox-mainarea`}>
              <div className="sandbox-mainarea-wrapper">
                <div className="sandbox-mainarea-alert">
                  <div className="sandbox-mainarea-alert-wrapper">
                    {as[example]}
                  </div>
                </div>
                {code && (
                  <div className="sandbox-mainarea-code" ref={ref}>
                    <Form>
                      <span className="sandbox-mainarea-code-brace">{'{'}</span>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="user_name">"user_name": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field name="user_name" component={InputTextField} />
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="text_template">"text_template": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field
                            name="text_template"
                            component={InputTextField}
                          />
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="count">"count": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field name="count" component={InputTextField} />
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="text">"text": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field name="text" component={InputTextField} />
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="image_url">"image_url": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <div className="sandbox-mainarea-code-control url">
                          <Field name="image_url" component={InputTextField} />
                        </div>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="animation_in">"animation_in": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field
                            name="animation_in"
                            component={SelectInAnimation}
                          />
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="animation_out">"animation_out": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field
                            name="animation_out"
                            component={SelectOutAnimation}
                          />
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="text_animation">
                          "text_animation":{' '}
                        </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field
                            name="text_animation"
                            component={SelectTextAnimation}
                          />
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <div className="sandbox-mainarea-code-field">
                        {'\u00A0 '}
                        <label htmlFor="font">"font": </label>
                        <span className="sandbox-mainarea-code-quote">"</span>
                        <span className="sandbox-mainarea-code-control">
                          <Field
                            name="font"
                            component={SelectFontFamily}
                          ></Field>
                        </span>
                        <span className="sandbox-mainarea-code-quote">"</span>
                      </div>
                      <span className="sandbox-mainarea-code-brace">{'}'}</span>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          )}
        </Formik>
        <div className="sandbox-bar">
          <div className="button-group">
            {as.map((item, index) => {
              return (
                <button
                  className={'btn tab'}
                  key={index}
                  data-value={index}
                  onClick={(e) => setExample(e.currentTarget.dataset.value)}
                >
                  {controls[index]}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

export default Sandbox;
