import React, { useState, useEffect, useRef } from 'react';
import { withField } from './withField';

var InputTextField = ({ name, value, setFieldValue, ...props }) => {
  var [textValue, setTextValue] = useState(value.toString());
  var ref = useRef();

  useEffect(() => {
    name && setFieldValue && setFieldValue(name, textValue);
  }, [name, textValue, setFieldValue]);

  useEffect(() => {
    calcInputWidth();
  }, []);

  const onChange = (e) => {
    calcInputWidth();
    setTextValue(e.target.value);
  };

  const calcInputWidth = () => {
    ref.current.style.width = (ref.current.value.length + 1) * 9.5 + 'px';
  };

  return (
    <input
      ref={ref}
      type="text"
      placeholder={`type value...`}
      value={textValue}
      onChange={onChange}
    />
  );
};

InputTextField = withField(InputTextField);

export default InputTextField;
