import React, { useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

const Button = ({ scroll }) => {
  const label = useRef(0);
  const arrowLeft = useRef(0);
  const arrowRight = useRef(0);

  function animateTo(i) {
    const tl = gsap.timeline({
      defaults: { duration: 0.5 },
    });
    tl.to(label.current, { x: 0 }, 0)
      .to(arrowLeft.current, { x: -window.innerWidth * 0.01563, opacity: 1 }, 0)
      .to(
        arrowRight.current,
        { x: window.innerWidth * 0.01563, opacity: 0 },
        0
      );
  }

  function animateFrom(i) {
    const tl = gsap.timeline({
      defaults: { duration: 0.5 },
    });
    tl.to(label.current, { x: -window.innerWidth * 0.01563 }, 0)
      .to(arrowLeft.current, { x: -window.innerWidth * 0.03125, opacity: 0 }, 0)
      .to(arrowRight.current, { x: 0, opacity: 1 }, 0);
  }

  return (
    <button
      className="btn large block accent animated"
      onClick={() => {
        gsap.to(window, {
          duration: 1,
          scrollTo: { y: scroll.current, offsetY: -71 },
        });
      }}
      onMouseEnter={(e) => {
        e.persist();
        animateTo(e.target);
      }}
      onMouseLeave={(e) => {
        e.persist();
        animateFrom(e.target);
      }}
    >
      <svg ref={arrowLeft} className="btn-arrow" viewBox="0 0 6 10">
        {' '}
        <path d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"></path>{' '}
      </svg>
      <i ref={label} className="btn-label">
        Try to create your own
      </i>
      <svg ref={arrowRight} className="btn-arrow" viewBox="0 0 6 10">
        {' '}
        <path d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"></path>{' '}
      </svg>
    </button>
  );
};

export default Button;
