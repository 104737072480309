export const inAnimationList = [
  'none',
  'fadeIn',
  'fadeInDown',
  'fadeInLeft',
  'fadeInRight',
  'fadeInUp',
  'fadeInTopLeft',
  'fadeInTopRight',
  'fadeInBottomLeft',
  'fadeInBottomRight',
  'backInDown',
  'backInLeft',
  'bounceIn',
  'bounceInDown',
  'bounceInLeft',
  'bounceInRight',
  'bounceInUp',
  'bounceInUp',
  'flip',
  'flipInX',
  'flipInY',
  'lightSpeedInRight',
  'lightSpeedInLeft',
  'rotateIn',
  'rotateInDownLeft',
  'rotateInDownRight',
  'rotateInUpLeft',
  'rotateInUpRight',
  'jackInTheBox',
  'rollIn',
  'zoomIn',
  'zoomInDown',
  'zoomInLeft',
  'zoomInRight',
  'slideInDown',
  'slideInLeft',
  'slideInRight',
  'slideInUp',
];

export const outAnimationList = [
  'none',
  'backOutDown',
  'backOutLeft',
  'backOutRight',
  'backOutUp',
  'bounceOut',
  'bounceOutDown',
  'bounceOutLeft',
  'bounceOutRight',
  'bounceOutUp',
  'fadeOut',
  'fadeOutDown',
  'fadeOutLeft',
  'fadeOutRight',
  'fadeOutUp',
  'fadeOutTopLeft',
  'fadeOutTopRight',
  'fadeOutBottomRight',
  'fadeOutBottomLeft',
  'flipOutX',
  'flipOutY',
  'lightSpeedOutRight',
  'lightSpeedOutLeft',
  'rotateOut',
  'rotateOutDownLeft',
  'rotateOutDownRight',
  'rotateOutUpLeft',
  'rotateOutUpRight',
  'hinge',
  'rollOut',
  'zoomOut',
  'zoomOutDown',
  'zoomOutLeft',
  'zoomOutRight',
  'zoomOutUp',
  'slideOutDown',
  'slideOutLeft',
  'slideOutRight',
  'slideOutUp',
];

export const textAnimationList = [
  '',
  'bounce',
  'pulse',
  'rubberBand',
  'tada',
  'wave',
  'wiggle',
  'wobble',
];

export const fontList = [
  'TT Firs Neue',
  'Roboto Condensed',
  'Roboto',
  'Open Sans',
  'Arial',
];
