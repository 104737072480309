import React from 'react';
import { textAnimationList } from './constants';

export const withTextAnimation = (Component) => {
  return ({ ...props }) => (
    <Component
      placeholder="Text Animation"
      style={{ width: 200 }}
      options={textAnimationList}
      {...props}
    />
  );
};
